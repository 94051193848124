<template>
  <div>
    <b-container>
      <b-row>
        <b-col v-for="(video, index) in videos" :key="index" lg="4" md="6" sm="12">
          <b-card :title="video.title" :img-src="video.thumbnail" img-alt="Image" img-top>
            <b-button @click="playVideo(video.id)">Play</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
 data() {
        return {
            videos: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    
    methods: {
         playVideo(videoId) {
           window.open('https://www.youtube.com/watch?v=' + videoId);
         // Ou você pode incorporar o vídeo em um modal ou diretamente na página
         // Exemplo de incorporação: <iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
       },
       getTutoriais() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_tutoriais.php"
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.videos = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {
        console.log('created')
        this.getTutoriais();
      

    },
}
</script>

<style>

</style>