<template>

 <b-card
    v-bind:title="prod.title"
   
    style="max-width: 18rem;"
    class="mb-2"
  >
   <b-card-text>Num só Produto pode cifrar para Violão,Cavaquinho, Viola, Teclado e Ukulele.</b-card-text>
    <b-card-text>
       
      <span  class="text-primary mx-auto">{{ prod.price | currency("R$") }}</span>
        <div class="text-primary mx-auto">{{ prod.complemento }}</div>
        <div class="text-primary mx-auto">{{ prod.complemento2 }}</div>
        <div class="text-primary mx-auto">{{ prod.complemento3 }}</div>
    </b-card-text>

    <b-button class="mt-2" block href="#" variant="danger" @click="clickAdd(prod,$event)">
          <b-icon icon="cart-plus"></b-icon>
          Carrinho
        </b-button> 
  </b-card>
   
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {
     
    },
    data: function() {
      return {
       
      }  
    },
     props: ['prod','foto'],
     computed: {
         nomemodal: function() {
            return '_prd_'+this.prod.id
         },
         imgfoto: function() {
               return this.foto

         } ,
        
        
        
          ...mapGetters(['getCartItems'])
         
     },
     methods: {
          clickAdd: function (prod,e) {
            
          console.log(e)
           
          let itemToAdd = prod;
         
          // add the item or increase quantity
          let itemInCart = this.getCartItems.filter(
            (item) => item.id === itemToAdd.id
          );
          let isItemInCart = itemInCart.length > 0;

          if (isItemInCart === false) {
            this.$store.commit('addItemCart',itemToAdd)
            

            this.$bvToast.toast('Item adicionado com sucesso', {
              title: "Adicionado ao carrinho",
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })


          } else {
            itemInCart[0].quantity += itemToAdd.quantity;
          }
          itemToAdd.quantity = 1;
        },
       
     }
     

}
</script>
  


<style scoped>

.btn-primary {
  color:#fff;
  background-color: #002046;
  border-color: #002046;
}

.btn-danger {
  color:#fff;
  background-color: #002046;
  border-color: #002046;
}
p {
      font-family: 'Poppins' !important;
  }
</style>