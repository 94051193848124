<template>
    <div v-bind:class="[classe1 , col]">
	<label>{{nome}}</label>
	<slot></slot>
	</div>
</template>
<script>
export default {
   props: ['nome','col'],
   data() {
		return {
			classe1:'form-group'
		}
	} 
}
</script>
<style scoped>

</style>