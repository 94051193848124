<template>

<b-card-group deck class='m-3' >
 <p class="display-4  text-center mt-3 text-primary" >Importador Arquivos Cifracil 2.3 para Cifracil 3.0</p>
 
   
  <b-card
    title="Software para Importacao Arquivos Cifracil 2.3"
   
    tag="article"
    style="max-width: 30rem;"
    class="mb-2 mx-auto" 
    align="center"

  >
    <b-card-text class='justificado'>
     Para usuarios que tem o cifracil 2.3 e possuem 
      varios arquivos feitos no cifracil e querem importar estes arquivos para 
      a nova versao do Cifracil 3.0 podem baixar este software gratuito.
    </b-card-text>

    <b-button href="http://cifracil.com/Versoes/InstaladorCifracilImportar.exe" variant="primary" class="m-2">Baixar Instalador </b-button>

  </b-card>
 
</b-card-group>
</template>

<script>
export default {

}
</script>

<style>
.justificado {
  text-align: justify;
  text-justify: inter-word;
}
</style>