<template>
 <div>
  <b-navbar  toggleable="lg" style="background-color: #002046; " type="dark" class="justify-content-start">
    <b-navbar-brand :to="{ name: 'Home'}">
    <img
              id="logo"
              src="../assets/logo_metade.jpg" 
              alt="Cifracil" 
              
            >
    </b-navbar-brand>

  <cfr-sidebar-cart id='cartmobile' class='mx-5'></cfr-sidebar-cart>

    <b-navbar-toggle  target="nav-collapse" ></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav >
      
      <b-navbar-nav class="m-4" fill>
    <b-nav-item :to="{ name: 'Home'}">Home</b-nav-item>
    <b-nav-item :to="{ name: 'Produtos'}">Comprar</b-nav-item>
    <b-nav-item :to="{ name: 'Tutoriais'}">Tutoriais</b-nav-item>
     <b-nav-item :to="{ name: 'Downloads'}">Downloads</b-nav-item>
    <b-nav-item :to="{ name: 'Licencas'}" v-if="logado">Licenças</b-nav-item>           

    <b-nav-item-dropdown left>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-icon icon="person-circle" variant="danger"></b-icon>
          </template>
          <b-dropdown-item v-if="!logado" @click="$bvModal.show('modal-prevent-login')"
          v-b-tooltip.hover.html="tipData" 
          >Entrar</b-dropdown-item>
           <b-dropdown-item v-if="!logado" @click="redirecionarParaCadastrar"
          v-b-tooltip.hover.html="tipData2" 
          >Criar Conta</b-dropdown-item>
         
         
          <b-dropdown-item v-if="logado" @click="sair()">Sair</b-dropdown-item>
      </b-nav-item-dropdown>

        <b-nav-item v-if="logado" href="#">Olá {{nomeusuario}}</b-nav-item>

                  <b-nav-item href="https://cifracil.com/cifradas" target="_blank">Acervo Musical</b-nav-item>
                 <b-nav-item href="https://cifracil.com/cavaquinho_help" target="_blank">HelpOnline</b-nav-item>              


<!-- <b-nav-item :to="{ name: 'Certificado'}">Certificado</b-nav-item> -->
 <b-nav-item href="https://api.whatsapp.com/send?phone=5531984640925&text=Ola ! Gostaria de conversar com vocês !">
           <b-icon id='chat' icon="chat-left-text" variant="danger"></b-icon>               
         </b-nav-item>
<b-nav-item>Whatsapp (31)98464-0925 </b-nav-item>
<!-- <b-nav-item href="https://actshare.com.br/" target="_blank">ActShare</b-nav-item> --> 
  <b-nav-text>  <cfr-sidebar-cart id="cartdesk"></cfr-sidebar-cart>  </b-nav-text>
      </b-navbar-nav>

  

    </b-collapse>
  
  </b-navbar>
   <!-- Espaço após a barra de navegação -->
    <div class="mb-2">
      <!-- Outro conteúdo da sua página aqui -->
    </div>
  <cfr-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
 
</cfr-politica-privacidade>
<cfr-lista-prod></cfr-lista-prod>

 </div>

</template>

<script>


import CfrPoliticaPrivacidade from "./CfrPoliticaPrivacidade.vue"
import CfrSidebarCart from "./CfrSidebarCart.vue"

export default {
  components: {
     
    CfrPoliticaPrivacidade,
    CfrSidebarCart,
   
  
  },
  data() {
    return {
      tipData: { title: '<em>Para acessar sua area exclusiva e ter uma experiência personalizada acesse sua conta</em>' },
      tipData2: { title: '<em>É Cliente novo ? Cria sua conta para receber notificações, acessar licencas e ter uma experiência personalizada</em>' },
      nomemodal:"0"
    }
  },
  computed: {
    logado() {
      return this.$store.getters.getLogado
    },
    nomeusuario() {
      return this.$store.getters.getNomeLog
    },
    qtdcart() {
      return this.$store.getters.getQtdCarrinho
    },
    politicatexto() {
      return this.$store.getters.getPoliticaTexto
    }
  },
  methods: {
    redirecionarParaCadastrar() {
      // Chame a rota nomeada "cadastrarme"
      this.$router.push({ name: 'Cadastrarme' });
    },
    sair() {
      console.log('vou sair')
      this.$store.dispatch('logout',false)
      this.$router.push({ name: 'Home' })
    }
  }

}
</script>

<style scoped>

@media (max-width: 575.98px) {
 #cartdesk {
  display: none !important;
 }
 #cartmobile {
  display: block !important;
 }
}
@media (min-width: 577px)  {
 #cartmobile {
  display: none !important;
 }
 #cartdesk {
  display: block !important;
 }
}


.numqtdcart {
  font-size: 8px !important;
  position:relative;
  top:-5px;
  color:yellow;
}



#nav-collapse {
  display:flex;
  justify-content: flex-end;
 left:200px;
  
}
.navbar-dark .navbar-nav .nav-link {
    color: white;
}
#logo {
  margin-left: 10px;
}
b-navbar {
  margin-bottom: 15px;
}
</style>
