<template>
<div>
  <b-container id="principal" fluid mt-5 >
    <b-row>
    <b-col cols="12" md="6">
      <div class="jumbotron jumbotron-fluid">
                
          <h1 class="display-4 justificado" v-html="titulo1"></h1>
          <p class="lead justificado" v-html="texto1"></p>
          <h3 class="display-3 justificado"> Já estamos na Google Play </h3> 
          <p class="lead justificado" >  <a href="https://play.google.com/store/apps/details?id=com.cifracil.cifracil" aria-label="Logotipo do Google Play" class="f0UV3d"><svg class="kOqhQd" aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" style="width: 40px; height: auto;"><path fill="none" d="M0,0h40v40H0V0z"></path><g><path d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z" fill="#EA4335"></path><path d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z" fill="#FBBC04"></path><path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z" fill="#4285F4"></path><path d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z" fill="#34A853"></path></g></svg><span aria-hidden="true">Google Play</span></a>
            Clicando ai na Loja do Google já poderá adquirir a versão Mobile / Tablet diretamente da loja. Aprender a usar esta versão é so seguir nossos tutorias. 
            A versão é praticamente identica , apenas mudando alguns aspectos da navegação para adaptação no seu celular. 
            <br> Em breve estaremos na Apple ! Tanto desktop quanto mobile.
          </p> 
      </div> 
    </b-col>
     <b-col cols="12" md="6">
      <div class="jumbotron jumbotron-fluid">
                
          <h1 class="display-4 justificado">Saiu Nova Vers&atilde;o windows agora em abril 2024 ! </h1>
          <p class="lead justificado" >Como Novidades da nova Vers&atilde;o podemos destacar:
          <ul> <li> Agora pode cifrar a mesma musica para Viol&atilde;o, Cavaquinho, Viola, Teclado e Ukulele </li>
          <li> Implementamos a fun&ccedil;&atilde;o de auto rolagem , com ajuste de velocidade, facilitando a leitura da musica rolando na tela.<li> As musicas ficam agora salvas numa base de dados da aplica&ccedil;&atilde;o , podendo ser 
          pesquisadas por varios filtros como Artista, Genero , Songbook e outros. </li>
          <li>Criamos uma ferramenta para constru&ccedil;&atilde;o de tablaturas, podendo incorpora-las na pagina cifrada </li>
          <li>Poder&aacute; ouvir a tablatura montada, pois para cada nota poder&aacute; associar uma figura musical de tempo </li>
          <li>Poder&aacute; pesquisar por artistas, albuns, discografia </li>
          <li>Agora podera monta seu songbook colando imagens na capa, podendo inclusive escrever pref&aacute;cio do songbook e incorpor as musicas com facilidade </li>
          <li>Configure os desenhos dos acordes colocando cores para cordas, nome acorde, fundo do desenho, nome das notas e simbolos e pode personalizar em 3 tamanhos diferentes </li>
          <li>Criamos no site na se&ccedil;&atilde;o tutorias varios videos para aprendizado , procurando assim facilitar o seu uso do cifracil 3.0 </li>
          </ul>
          </p>
                  
      </div>
    </b-col>
    </b-row>
  </b-container>

<b-container fluid>
<cfr-novidades></cfr-novidades>
</b-container>

<b-container fluid>
<cfr-godin></cfr-godin>
</b-container>


<cfr-lista-vantagens></cfr-lista-vantagens>

<cfr-lista-testemunhos></cfr-lista-testemunhos>
 
<cfrtutoriais></cfrtutoriais> 

<cfr-lista-prod :id='id'> </cfr-lista-prod>

<cfrdownload></cfrdownload>

<cfr-faq></cfr-faq>

<b-container fluid text-center style="background-color: #002046;">
<cfr-footer ></cfr-footer>
</b-container>


 <b-modal ref="my-modal" 
 hide-footer 
 no-close-on-esc
 no-close-on-backdrop
 hide-header-close
 title="Termos de Uso">
      <div class="d-block text-center">
          
	<p>Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_3')">politica de privacidade</a> e, ao continuar navegando, você concorda com estas condições.</p>


  <cfr-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
</cfr-politica-privacidade>

      </div>
     <b-button class="m-2" variant="outline-success" block @click="aceitarpolitica">Aceitar</b-button>
    </b-modal>



</div> 
   

</template>

<script>
import { mapGetters } from "vuex"
import CfrListaProd from './CfrListaProd.vue'
import CfrFooter from './CfrFooter.vue'
import CfrPoliticaPrivacidade from "./CfrPoliticaPrivacidade.vue"
import CfrListaTestemunhos from "./CfrListaTestemunhos.vue"
import CfrListaVantagens from './CfrListaVantagens.vue'
import CfrNovidades from './CfrNovidades.vue'
import CfrFaq from './CfrFaq.vue'
import CfrGodin from './CfrGodin.vue'
import cfrtutoriais from './cfrtutorias.vue'
import cfrdownload from './cfrdownload.vue'






//import ActCarrinho from './ActCarrinho.vue'
export default {
  props: ['id'],
  data() {
    return {
       nomemodal:"3"
    }
  },
    
  
  components: {
     CfrListaProd,
     CfrFooter,
     CfrPoliticaPrivacidade,
     CfrListaTestemunhos,
     CfrListaVantagens,
     CfrFaq,
     CfrNovidades,
     CfrGodin,
     cfrtutoriais,
     cfrdownload
     
    
    
  } ,
   computed: {
  ...mapGetters( {texto1:'getTxt1',
                  titulo1:'getTituloTxt1',
                  videohome:'getVideoHome',
                  ExibirPolitica:'getAceitarPolitica',
                  qtdTestemunhos:'getQtdTestemunhos'
                  }),
    politicatexto() {
      return this.$store.getters.getPoliticaTexto
    }                  
    
   }, 

   mounted() {
      if (this.ExibirPolitica) {
        
         this.$refs['my-modal'].show()
       
      }
   },
   methods: {
      aceitarpolitica() {
          sessionStorage.setItem("politicaseglgpd","1")
          this.hideModal()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      }
   }

  
  
}
</script>

<style scoped>
  a {
      color: #00a69c;
  }
  #principal {
     margin-top:20px;
  }
  #logoseg {
    margin-top:-16px;

  }
  .direita {
  text-align: right;
  }

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.justificado {
  text-align: justify;
  text-justify: inter-word;
}
</style>