import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/CfrHome'
import Licencas from '../components/CfrCodLiberacao'
import Produtos from '../components/CfrListaProd'
import Downloads from '../components/cfrdownload'
import Cadastrarme from '../components/CfrCadastrarMe'
import Tutoriais from '../components/cfrtutorias'
Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/licencas',
            name: 'Licencas',
            component: Licencas
        },
        {
            path: '/produtos',
            name: 'Produtos',
            component: Produtos
        },
        {
            path: '/cadastrarme',
            name: 'Cadastrarme',
            component: Cadastrarme
        },
        {
            path: '/tutoriais',
            name: 'Tutoriais',
            component: Tutoriais
        },
        {
            path: '/downloads',
            name: 'Downloads',
            component: Downloads
        },

]
})