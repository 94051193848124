<template>
    <b-row style=" background-color: #002046" class="pb-3">
      
       <b-col class="mx-4" sm="6" md="2">
        <img
              id="logofooter"
              src="../assets/logo.jpg"
              alt="Cifracil" 
              class="img-fluid"
            />
       
       </b-col>

       <b-col sm="6" md="3">
            <b-card title="Endereço"  bg-variant="primary" border-variant="primary" text-variant="white" class="mt-2 border-0">
                <b-card-text>
                <small> {{empresa.end}} <br>{{empresa.end2}} </small>
                </b-card-text>
                <b-card-text>
               <small> {{empresa.cnpj}}</small>
                </b-card-text>
            </b-card>
       </b-col>
       <b-col sm="6" md="3">
          <b-card title="Paginas"  bg-variant="primary" border-variant="light" text-variant="white" class="mt-2 border-0">
          <b-card-text>
            <b-nav vertical class="w-30">
                <b-nav-item :to="{ name: 'Home'}">Home</b-nav-item>
                 <b-nav-item href="https://cifracil.com/cifradas" target="_blank">Acervo Musical</b-nav-item>
                 <b-nav-item href="https://cifracil.com/cavaquinho_help" target="_blank">HelpOnline</b-nav-item>              
         
<b-nav-item href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_2')">Politica de Privacidade</b-nav-item>

        

            </b-nav>

          </b-card-text>
          </b-card>  
       </b-col> 

       <b-col sm="6" md="3">
            <b-card title="Fale Conosco"  bg-variant="primary" border-variant="light" text-variant="white" class="mt-2 border-0">
                <b-card-text>
                <small>Whatsapp: {{empresa.tel}} </small>
                </b-card-text>              

                <b-card-text>
                <small> {{empresa.email}} </small>
                </b-card-text>
                <b-card-text>
                <small>Tel: {{empresa.tel}} </small>
                </b-card-text>
            </b-card>
       </b-col>
  <cfr-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
</cfr-politica-privacidade>
    </b-row>
</template>

<script>

import CfrPoliticaPrivacidade from "./CfrPoliticaPrivacidade.vue"
export default {
   components: {
     CfrPoliticaPrivacidade
   },
   data() {
        return {
            empresa: '',
            loading:false,
            erro:'',
            show:false,
            nomemodal:"2",
      tipData: { title: '<em>Para ver Pedidos e ter uma experiência personalizada acesse sua conta</em>' },
      tipData2: { title: '<em>É Cliente novo ? Cria sua conta para ver Pedidos e ter uma experiência personalizada</em>' },

        }

    },
    computed: {
      politicatexto() {
        return this.$store.getters.getPoliticaTexto
      },
      logado() {
        return this.$store.getters.getLogado
      }
    },
 methods: {


 getEmpresa() {
      
      this.loading = true;
      const url = "https://cifracil.com/backend/ajax_empresa_footer.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.empresa = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },

    mounted() {

        this.getEmpresa();

    }

 
}
</script>

<style>

#logofooter {
 
  margin-top: 30px;
 
  
}
#logocandrevafooter {
  margin-top :40px; 
  
 
}
@media screen and (min-width: 768px) {
  #logocandrevafooter {
    margin-top :40px;
    height: 40%;
    width: 60%; 
  }
 
}
  
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
}

</style>