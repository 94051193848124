import { render, staticRenderFns } from "./CfrHome.vue?vue&type=template&id=f70f73ce&scoped=true"
import script from "./CfrHome.vue?vue&type=script&lang=js"
export * from "./CfrHome.vue?vue&type=script&lang=js"
import style0 from "./CfrHome.vue?vue&type=style&index=0&id=f70f73ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70f73ce",
  null
  
)

export default component.exports