import { render, staticRenderFns } from "./CfrListaProd.vue?vue&type=template&id=730111d8&scoped=true"
import script from "./CfrListaProd.vue?vue&type=script&lang=js"
export * from "./CfrListaProd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730111d8",
  null
  
)

export default component.exports