<template>
  <div id="container">
  <b-row>
  <b-col cols="12" md="8">
   <b-alert show variant="info" class="mt-5 justificado">Ao instalar o cifracil, um icone é colocado em sua área de trabalho. A clicar nele o software apresentará uma tela com um código que é uma sequência de números e letras, ao qual chamamos aqui de código do computador. Você poderá preencher abaixo aqui este codigo, para obter o codigo de liberação do cifracil na sua maquina. Basta preencher o codigo referente a licença que você adquiriu, clicar no botão Obter Cod Liberação e o código gerado sera mostrado abaixo. Então você copia ele e cola no cifracil e dá um ok e o software estará liberado para utilização no seu computador. Em caso de troca de maquina ou hd ou reinstalação do windows este codigo tera que ser gerado novamente.</b-alert>
  <table class="table table-striped">
   <thead>
   <tr><th>Aplicação</th><th>Data Licença</th><th>Cod.Computador</th><th class="d-none d-md-table-cell">Link Baixar Instalador</th></tr>
   </thead>
   <tbody>
    <tr v-for="lic in licencas" :key="lic.codapl">
        <td>{{ lic.aplicacao }}</td>
        <td>{{ lic.dtcompra }}</td>
        <td>
          <input type="text" v-model="lic.codigo" />
          <button @click="obterCodigo(lic)">Obter Cod.Liberação</button>
        </td>
        <td class="d-none d-md-table-cell"><a :href="lic.download">Baixar Instalador</a></td>
    </tr>
   </tbody>
   </table>
   <b-alert show variant="primary" v-show="codlib!=''">Codigo Liberação: {{codlib}}</b-alert>   
   </b-col>
   <b-col cols="12" md="4">
   <cfr-cadastrar-me></cfr-cadastrar-me>
   </b-col>
  </b-row>
  </div>
</template>

<script>
import swal from 'sweetalert'
import CfrCadastrarMe from './CfrCadastrarMe'

export default {
  components: {
    CfrCadastrarMe
  },
    data() {
        return {
            licencas: [],
            loading:false,
            erro:'',
            codlib:'',
            show:false
        }
    },
  computed: {
      ClientId() {
          return this.$store.getters.getClientId
        },
  },
  watch: {
    
      ClientId: function(newValue) {
          
            console.log('watch '+newValue)
          
           
            this.getLicencas()

          
      }    
  },   

 methods: {
    obterCodigo(lic) {
      if (lic.codigo == "") {
          swal("Aviso", "Codigo Computador deve ser preenchido !", "error");
          return false
      }
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_codlib.php";
      const dataJson = 'codapl='+lic.codapl+'&serial='+lic.codigo
      console.log('dataJson'+dataJson);
      fetch(url,{
                   method: "POST",
                   headers: { "Content-Type":"application/x-www-form-urlencoded" },
                   body: dataJson
              })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
            console.log('obter codigo lic')
            console.log(data)
          this.codlib = data.codlib;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    getLicencas() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_licencas.php";
      const dataJson = 'codusr='+this.$store.getters.getClientId
      console.log('getlicenca com '+dataJson)
      fetch(url,{
                   method: "POST",
                   headers: { "Content-Type":"application/x-www-form-urlencoded" },
                   body: dataJson
              })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
            console.log('getlicencas')
            console.log(data)
          this.licencas = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
    },


   },


    mounted() {
        console.log('mounted')
        this.getLicencas();

    }
}
</script>
  
<style>
#container {
    margin-top:100px;
    padding:10px;
}  
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background-color: #f8f9fa;
}

/* Estilo para tabelas com borda */
.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

/* Estilo de cabeçalho de tabela listrado */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Estilo de tabela com hover */
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Estilo para tabelas responsivas */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Estilo para tabelas pequenas */
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

/* Cores de texto e links */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
  color: #fff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

/* Outras cores podem ser definidas da mesma maneira */

/* Classes de cores de links */
.table a {
  color: #007bff;
}

.table a:hover {
  color: #0056b3;
  text-decoration: none;
}

.justificado {
  text-align: justify;
  text-justify: inter-word;
}
</style>