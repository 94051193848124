<template>
  <div>
    <b-button v-b-toggle.sidebar-footer variant="danger">
     <b-icon icon="cart">
     </b-icon>
     <b-badge class="ml-2" v-bind:class="{ badgeitens:temitenscarrinho}"  >{{ QtdCarrinho }}</b-badge>
     
    </b-button>
    <b-sidebar id="sidebar-footer" width="340px" title="" bg-variant="light" right shadow>

      <div class="px-2 py-2">
        <div v-show="!comprar" class="col-12">

          <cfr-mini-cart></cfr-mini-cart>


 <cfr-cupom class="m-2"></cfr-cupom>
 <b-row no-gutters >
  <b-col md="8" >
    <span  class="text-black m-2"><strong>Total</strong> </span>     
  </b-col>
  <b-col md="4">
    <span class="direita"><strong>{{ Total | currency("R$") }}</strong> </span>
  </b-col>
</b-row>
<hr>

<b-row v-if="VlrCupom > 0">
  <b-col md="8">
    <span class="text-danger m-2">Cupom </span>
  </b-col>
  <b-col md="4">
    <span class="text-danger direita"> {{ VlrCupom | currency("R$") }} </span>
  </b-col>
</b-row>
<hr>
<b-row>
  <b-col md="8">
    <span class="text-danger m-2">Total </span>
  </b-col>
  <b-col md="4">
    <span class="text-danger direita"> {{ Liquido | currency("R$") }} </span>
  </b-col>
</b-row>

        
          <b-row align-h="end" class="m-2">
            <b-col cols="4" >    
            <b-button variant="success" size="sm" v-on:click="iniciarTabCompra">Comprar</b-button>
            </b-col>
         </b-row>



        </div> <!--coluna 1 !Comprar -->


      <div v-show="comprar">
       
        <b-button-toolbar class="mb-2" key-nav aria-label="Toolbar with button groups">
            <b-button-group class="mx-1">
            <b-button v-show="Logado && tabIndex == 0" @click="tabIndex++">Continuar <b-icon icon="arrow-right-circle"></b-icon></b-button>
            <b-button v-show="tabIndex > 0" @click="tabIndex--"><b-icon icon="arrow-left-circle"></b-icon> Voltar</b-button>
            <b-button  @click="voltarTabCompra"><b-icon icon="cart"></b-icon> Carrinho {{QtdCarrinho}}</b-button>
            </b-button-group>
        </b-button-toolbar>

        <div>
        <b-card no-body>
            <b-tabs card v-model="tabIndex" active-nav-item-class="font-weight-bold text-uppercase text-danger">
            <b-tab title="Identificação" active>
                <cfr-login-modal></cfr-login-modal>
                
                <cfr-conta-modal></cfr-conta-modal>
               <b-alert class='mt-3' v-bind:show="temmsg" v-bind:variant="classemsg"> {{msgconta}}</b-alert>
            </b-tab>
            
            <b-tab title="Finalizar Pedido">
                <b-card-text title="Revisar Pedido e Pagar"> 
                <cfr-resumo-pedido v-show="Logado"> </cfr-resumo-pedido>
                 </b-card-text>
            </b-tab>

            </b-tabs>
        </b-card>
        </div>

      </div><!-- fim coluna 2-->



      </div> <!-- fim row-->
      
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CfrLoginModal from "./CfrLoginModal.vue"
import CfrContaModal from "./CfrContaModal.vue"
import CfrMiniCart from "./CfrCar.vue"
import CfrResumoPedido from "./CfrResumoPedido.vue"
import CfrCupom from './CfrCupom.vue'
export default {
    data: function() {
      return {
         comprar:false,
         tabIndex:0,
         loading:false 
      }
    },
    components: {
       
        CfrMiniCart,
        CfrLoginModal,
        CfrContaModal,
        CfrResumoPedido,
        CfrCupom
    },
    computed: {
        ...mapGetters({
      Total:'getTotalCarrinho',
      QtdCarrinho:'getQtdCarrinho',
      PerCupom:'getPerCupom',
      CupomVlr:'getCupomVlr',
      Logado:'getLogado',
      ClientId:'getClientId'

      }),
       VlrCupom() {
            if (this.CupomVlr > 0) {
               return this.CupomVlr
            } else if (this.PerCupom < 0) {
                    return 0
            } else {
                return this.Total * this.PerCupom / 100    
            }
        },
        Liquido() {
            return this.Total - this.VlrCupom
        },
        PercQtd() {
           let vlrfinal = this.Total - this.VlrCupom
            if (vlrfinal >= 0) {
              return vlrfinal
            } else {
              return 0.00
            }
        },
         msgconta() {
            return this.$store.getters.getMsgConta
        },
       temmsg() {
            let aux = this.msgconta
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
         contacriada() {
          return this.$store.getters.getContaCriada
        },
         classemsg() {
            if (this.contacriada) {
                return "success"
            } else {
               return "danger"   
            }
        },
        temitenscarrinho() {
          if (this.QtdCarrinho > 0) {
            return true
          } else {
            return false
          }
        }

    },
    methods: {
      iniciarTabCompra() {
        this.comprar = true

      } , 
      voltarTabCompra() {
          this.comprar = false         
      },

    },

    watch: {
        Logado: function(newValue) {
            if ( newValue && (this.tabIndex == 0)) {
                this.tabIndex = 1
            }

        },
          mensagem: function (newValue) {
          
               console.log('change mensagem'+newValue)
               this.dismissSecs = 5
              
               this.showAlert()
            
        }


       
    },
    created() {
       // this.getTabDesc()
    }

}
</script>

<style scoped>
.btn-danger {
  color:#fff;
  background-color: #921543;
  border-color: #921543;
}
.bg-danger {
    background-color: #921543 !important;
}
.badgeitens {
  background-color:rgb(77, 233, 46)
}

.text-danger {
    --bs-text-opacity: 1;
    color:#921543 !important;
}

.cart__table {
  width: 100%;
  
  margin-bottom: 15px;
}
.cart__table th {
  font-size: 16px;
  font-weight: 600;
  color: rgba(55, 55, 55, 0.7);
  line-height: 100%;
  padding: 0 3px 15px 2px;
}
.cart__table th:last-child {
  width: 24px;
  padding: 0 0 15px 0;
}
.cart__table td {
  font-size: 16px;
  font-weight: 400;
  color: #373737;
  padding: 15px 3px 15px 2px;
}
.cart__table td:last-child {
  width: 24px;
  padding: 15px 0;
}

.cart__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
.direita {
    float:right !important;
    padding-right:15px;
}
.total_table {

  margin-bottom: 15px;
}

.total_table td {
  width:25px;
  font-size: 14px;
  color: #373737;
  margin-bottom: 10px;
  padding:5px;
  text-align: right ;      

}

.total_table td:first-child {
  text-decoration: line-through;
  border:red
}

.cart__systems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  color: rgba(55, 55, 55, 0.4);
  width: 100%;
  margin-top: 20px;
}
.cart__systems i {
  margin-right: 15px;
}
.cart__systems i:last-child {
  margin-right: 0;
}
</style>