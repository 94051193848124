<template>
<div  class="mt-2">
<b-button v-b-modal.modal-prevent-conta variant="danger">Quero Criar Minha Conta</b-button>

     <b-modal
      id="modal-prevent-conta"
      ref="modal"
      title="Dados da Minha Conta"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" class="form was-validated" @submit.stop.prevent="handleSubmit">
       <div class="form-row">
        <cfr-rotulo nome="Nome" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.nome"
            required
          />
          <div class="invalid-feedback">Por favor, informe seu Nome.</div>
        </cfr-rotulo>
        <cfr-rotulo nome="Email" col="col-md-12">
          <input
            type="email"
            class="form-control"
            v-model="cliente.email"
            required
          />
          <div class="invalid-feedback">Por favor, informe seu Email.</div>
        </cfr-rotulo>
        
        <cfr-rotulo nome="Confirmação do Email" col="col-md-12">
          <input
            type="email"
            class="form-control"
            v-model="cliente.emailconfirma"
            required
            @paste.prevent
          />
          <div class="invalid-feedback">Por favor, informe seu email novamente.</div>
        </cfr-rotulo>


        <cfr-rotulo v-show="!Logado" nome="Senha" col="col-md-12">
          <input
            type="password"
            class="form-control"
            v-model="cliente.senha"
            required
          />
          <div class="invalid-feedback">Por favor, crie uma senha.</div>
        </cfr-rotulo>

      </div>

      <div class="form-row">
        <cfr-rotulo nome="Tipo Pessoa" col="col-md-12">
          <div>
            <input type="radio" value="J" v-model="cliente.tipopessoa" />
            Pessoa Jurídica
          </div>
          <div>
            <input type="radio" value="F" v-model="cliente.tipopessoa" />
            Pessoa Física
          </div>
        </cfr-rotulo>
        <cfr-rotulo v-if="cliente.tipopessoa == 'F'" nome="CPF" col="col-md-12">
          <input
            v-maska="'###.###.###-##'"
            v-bind:class="[classeInput, cpfClass]"
            v-model="cliente.cpf"
            @change="altcpf"
          />
          <div class="invalid-feedback">Por favor, informe um cpf válido.</div>
        </cfr-rotulo>
        <cfr-rotulo v-else nome="CNPJ" col="col-md-12">
          <input
            v-maska="'##.###.###/####-##'"
            v-bind:class="[classeInput, cnpjClass]"
            v-model="cliente.cnpj"
            @change="altcnpj"
          />
          <div class="invalid-feedback">Por favor, informe um CNPJ válido.</div>
        </cfr-rotulo>
        <cfr-rotulo
          v-show="cliente.tipopessoa == 'J'"
          nome="Razão Social"
          col="col-md-12"
        >
          <input
            type="text"
            class="form-control"
            v-model="cliente.razao"
            
          />
        </cfr-rotulo>
        <cfr-rotulo nome="CEP" col="col-md-12">
          <input
            v-maska="'#####-###'"
            class="form-control"
            v-model="cliente.cep"
            @change="pegaEndereco"
            required
          />
          <div class="invalid-feedback">Por favor, informe seu cep.</div>

        </cfr-rotulo>
      </div>
      <div class="form-row">
        <cfr-rotulo nome="Endereco" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.endereco"
            required
          />
          <div class="invalid-feedback">Por favor, informe seu Endereço.</div>
        </cfr-rotulo>
        <cfr-rotulo nome="Número" col="col-md-6">
          <input type="text" class="form-control" v-model="cliente.endnum" required />
          <div class="invalid-feedback">Por favor, informe número do Endereço.</div>
        </cfr-rotulo>
        <cfr-rotulo nome="Complemento" col="col-md-6">
          <input type="text" class="form-control" v-model="cliente.compl" />
        </cfr-rotulo>
        <cfr-rotulo nome="Bairro" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.bairro"
            required
          />
          <div class="invalid-feedback">Por favor, informe seu bairro.</div>
        </cfr-rotulo>
      </div>
      <div class="form-row">
        <cfr-rotulo nome="Cidade" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.cidade"
            required
          />
        <div class="invalid-feedback">Por favor, informe a sua cidade.</div>
        </cfr-rotulo>
        <cfr-rotulo nome="Estado" col="col-md-6">
          <input
            type="text"
            class="form-control"
            v-model="cliente.uf"
            required
          />

        </cfr-rotulo>
        <cfr-rotulo nome="Tel Comercial" col="col-md-12">
          <input
            v-maska="['(##)####-####', '(##)#####-####']"
            class="form-control"
            v-model="cliente.telcom"
            
          />
         <div class="invalid-feedback">Por favor, informe seu telefone comercial.</div>

        </cfr-rotulo>
        <cfr-rotulo nome="Celular" col="col-md-12">
          <input
            v-maska="'(##)#####-####'"
            class="form-control"
            v-model="cliente.celular"
          />
        </cfr-rotulo>
      </div>
      <br>

      <b-form-checkbox
      id="checkbox-1"
      v-model="viupolitica"
      name="checkbox-1"
      value="accepted"
      unchecked-value="not_accepted"
    >
      Ao enviar meus dados eu concordo com a <a href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_10')">politica de privacidade</a>
    </b-form-checkbox>


      </form>


    <cfr-politica-privacidade
    :id='idmodal'
    :texto='politicatexto'

    >
    </cfr-politica-privacidade>


    </b-modal>
</div>    
</template>

<script>
import CfrRotulo from "./CfrRotulo.vue"
import CfrPoliticaPrivacidade from "./CfrPoliticaPrivacidade.vue"
export default {

  components: {
     CfrRotulo,
     CfrPoliticaPrivacidade
  },  
  data() {
    return {
      cliente: {
        id:0,
        nome: "",
        cpf: "",
        cnpj: "",
        razao: "",
        email: "",
        emailconfirma:"",
        senha: "",
        tipopessoa: "F",
        endereco: "",
        endnum: "",
        compl: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        telcom: "",
        celular: "",
        
      },
      cpfClass: "",
      cnpjClass: "",
      classeInput: "form-control",
      loading: false,
      error: "",
      labelBotao:"Enviar",
      idmodal:"10",
      viupolitica:false
     
    }
  },    
 computed: {
        contacriada() {
          return this.$store.getters.getContaCriada
        },
        mensagem() {
            return this.$store.getters.getMsgConta
        },
        temmsg() {
            let aux = this.mensagem
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
        Logado() {
          return this.$store.getters.getLogado
        },
        politicatexto() {
           return this.$store.getters.getPoliticaTexto
        },
       
       
        ClientId() {
          return this.$store.getters.getClientId
        },
        
        classemsg() {
            if (this.contacriada) {
                return "success"
            } else {
               return "danger"   
            }
        }
  },
 watch: {
    
      ClientId: function(newValue) {
          if (newValue > 0) {
            this.labelBotao = "Alterar"
            this.cliente.id = newValue
            this.getDadosConta(newValue)

          }
      }    
  },
  methods: {
       checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
       
        return valid
      },
      resetModal() {
       
        this.nameState = null
        this.senhaState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        if (this.cliente.email != this.cliente.emailconfirma) {
          this.$bvModal.msgBoxOk("Os emails estão diferentes")
          return false
        }

        if (this.cliente.tipopessoa == 'J')  {
           if (this.cliente.cnpj == '') {
              this.$bvModal.msgBoxOk("Preencha CNPJ");
              return false
           }
           if (!this.validarCNPJ(this.cliente.cnpj)) {
                 this.$bvModal.msgBoxOk("CNPJ Invalido");
                 return false
           }
        }
        if (this.cliente.tipopessoa == 'F')  {
            if (this.cliente.cpf == '') {
                this.$bvModal.msgBoxOk("Preencha CPF");
                return false
            }
            if (!this.cpfValido(this.cliente.cpf)) {
                 this.$bvModal.msgBoxOk("CPF Invalido");
                 return false
            }
        }
        if (!this.viupolitica) {
                this.$bvModal.msgBoxOk("Você deve marcar a caixinha concordando com a política de segurança antes ! ");
                return false

        }

        if ((!this.Logado) && (this.cliente.senha == "")) {
                this.$bvModal.msgBoxOk("Preencha a Senha");
                return false

        }
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        if (!this.checkFormValidity()) {
            //alert('form invalido')
          return
        }
        this.id = this.ClientId
        this.$store.dispatch('criarconta',this.cliente)
        // Hide the modal manually
        this.$nextTick(() => {
           this.$bvModal.hide('modal-prevent-conta')
        })
        //alert('vou enviar form')
      },    



getDadosConta(id) {
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_conta.php?id="+id;
      //console.log(url);
      fetch(url)
        .then((response) => {
          //console.log(response);
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.cliente = data;
          this.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
          //this.error = error;
        });
    },


    cpfValido(cpf) {
      cpf = this.replaceAll(cpf, ".", "");
      cpf = this.replaceAll(cpf, "-", "");
      var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11) return false;
      for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(0)) return false;
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(1)) return false;
        return true;
      } else return false;
    },

    replaceAll(str, de, para) {
      let pos = str.indexOf(de);
      while (pos > -1) {
        str = str.replace(de, para);
        pos = str.indexOf(de);
      }
      return str;
    },

    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },

    altcpf() {
     
      if (this.cpfValido(this.cliente.cpf)) {
        this.cpfClass = "is-valid";
      } else {
        this.cpfClass = "is-invalid";
      }
    },
    altcnpj() {
      if (this.validarCNPJ(this.cliente.cnpj)) {
        this.cnpjClass = "is-valid";
      } else {
        this.cnpjClass = "is-invalid";
      }
    },

    pegaEndereco() {
      fetch("https://viacep.com.br/ws/" + this.cliente.cep + "/json")
        .then((response) => {
         
          if (response.ok) {
           
            return response.json();
          }
        })
        .then((data) => {
         
          this.cliente.endereco = data.logradouro;
          this.cliente.bairro = data.bairro;
          this.cliente.cidade = data.localidade;
          this.cliente.uf = data.uf;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.error = error;
        });
    }


  }
}
</script>

<style scoped>
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #00a69c !important;
}
</style>