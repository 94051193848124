<template>
  <div id="app">
    <cfr-head/>
       <keep-alive>
			<router-view></router-view>
		</keep-alive>
  </div>
</template>

<script>
import CfrHead from './components/cfr-head.vue'


export default {
  name: 'App',
  components: {
    CfrHead,
 
  },
  created() {
    document.title = "cifracil"
    console.log('vou fazer dispatch loadempresa')
    this.$store.dispatch("loadempresa")
    if (sessionStorage.getItem("politicaseglgpd") === null) {
      this.$store.commit('setAceitarPolitica',true)
    }  
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
</style>
