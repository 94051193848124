<template>
<b-card-group deck class='m-3' >
 <b-row>
    <p class="display-4  text-center mt-3 text-primary" >Testemunhos</p>
            <b-col cols="12" md="auto p-2" v-for="testemunho in testemunhos" :key="testemunho.id" > 
         
            <b-card style="width: 18rem;"
               :title="testemunho.titulo" 
               :sub-title="testemunho.subtitulo"
               align="center"
               tag="article"
               class="h-100 m-2 "
               >
                    
                    <b-img :src="testemunho.foto" rounded="circle" v-bind="mainProps" img-left class="mb-3" img-alt="Image"> 
                    </b-img>
               
                    <b-card-text >
                       <div v-html="testemunho.texto" ></div>
                    </b-card-text>
               
              
            </b-card>
        </b-col>    
    </b-row> 
 </b-card-group>      
</template>

<script>
 
export default {
   
    data() {
        return {
            testemunhos: [],
            loading:false,
            erro:'',
            show:false,
            mainProps: {
                        
                         blankColor: '#777', 
                         width: 75, height: 75, class: 'm1' }
           
        }
        

    },
    
    methods: {
       getTestemunhos() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_testemunhos.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
            console.log('testemunhos')
            console.log(data)
          this.testemunhos = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {
        console.log('mounted')
        this.getTestemunhos();

    }
}
</script>

<style scoped>

</style>