<template>
   <b-card-group deck class='m-3' >
        <b-row>  

 <p class="display-4  text-center mt-3 text-primary" >Vantagens em usar Cifracil</p>
            <b-col cols="12" md="auto p-2" v-for="vant in vantagens" :key="vant.id" > 
            
            <b-card style="max-width: 18rem;"
                :title="vant.titulo"
                tag="article"
                align="center"
                class="h-100 m-2 "
            >

            <div style="font-size: 4rem;">
                <b-icon v-bind:icon="vant.icone" class="border rounded p-2"></b-icon>
            </div> 

             <b-card-text>
             {{vant.title}}
             </b-card-text>
             <b-card-text >
              <div v-html="vant.descricao" ></div>
             </b-card-text>

            </b-card>

            </b-col>

       </b-row>
            
    </b-card-group>


</template>

<script>
export default {
  data() {
        return {
            vantagens: [],
            loading:false,
            erro:'',
            show:false
        }

    },
 methods: {
       getVantagens() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_vantagens.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.vantagens = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {

        this.getVantagens();

    }


}
</script>

<style scoped>

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
}

</style>