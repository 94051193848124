<template>
  <div>
   <b-row>
    <p class="display-4  text-center mt-3 text-primary" >Saiu a Nova Versao Cifracil 3.0 !</p>

      
    </b-row>
<b-row>  


    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="8000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        caption="Músicas salvas no Banco de Dados do Cifracil e para Varios instrumentos numa so Versão !"
        text="As músicas agora são armazenadas dentro do cifracil numa base de dados, e não externamento em arquivos separados como eram na versão anterior"
        img-src="https://cifracil.com/imagens/cifracil_desktop_1.jpg"
      ></b-carousel-slide>

      <!-- Text slides with image -->
      <b-carousel-slide
        caption="Ferramenta do Cifracil para fazer e ouvir Tablatura"
        text=""
        img-src="https://cifracil.com/imagens/cifracil_desktop_2.jpg"
      ></b-carousel-slide>

      <!-- Text slides with image -->
      <b-carousel-slide
        caption="Novo Preview do documento"
        text="O novo preview do documento possui Thumbnails das Paginas e uma barra de ferramentas abaixo onde tem opções de impressão, Exportação PDF e outras..."
        img-src="https://cifracil.com/imagens/cifracil_desktop_2a.jpg"
      ></b-carousel-slide>

        <!-- Text slides with image -->
        <b-carousel-slide
        caption="Novo Preview do documento"
        text="O novo preview do documento possui Thumbnails das Paginas e uma barra de ferramentas abaixo onde tem opções de impressão, Exportação PDF e outras..."
        img-src="https://cifracil.com/imagens/cifracil_preview.jpg"
      ></b-carousel-slide>

      <!-- Text slides with image -->
      <b-carousel-slide
        caption="Dicionário dos Acordes na mesma tela do editor"
        text="Ficou mais facil acessar o dicionário de acordes , pois na edição da música poderá pesquisar e incluir novos acordes sem sair do editor"
        img-src="https://cifracil.com/imagens/cifracil_desktop_novo_4.jpg"
      ></b-carousel-slide>
    </b-carousel>
    <p class="mt-4">
      Slide #: {{ slide }}<br>
      Sliding: {{ sliding }}
    </p>
    </b-row> 
   
  </div>
 
</template>

<script>

export default {
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>

<style>
.justificado {
  text-align: justify;
  text-justify: inter-word;
}
</style>