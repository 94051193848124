<template>

<b-card-group deck class='m-3' >
 <p class="display-4  text-center mt-3 text-primary" >Songbook no Site do Eduardo Gudin elaborado no Cifracil</p>
 
   
  <b-card
    title="SongBook do Eudardo Gudin no Cifracil"
    img-src="https://cifracil.com/imagens/songbookgudin.png"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 30rem;"
    class="mb-2 mx-auto" 
    align="center"

  >
    <b-card-text class='justificado'>
      Com muita alegria, tivemos a grata surpresa de o cifracil ter sido escolhido para montar 2 songbooks do grande compositor, arranjador e violonista Eduardo Gudin sendo disponibilizados em <a href='https://www.obrascompletas.eduardogudin.com/' target='_blank'>seu site Obras Completas de Eduardo Godin</a>
      Para quem não ligou o nome a pessoa, facilmente se lembrará do grande sucesso <a href='https://www.youtube.com/watch?v=yF6Lbnzv92I' target="_blank"> Verde interpretado por Leila Pinheiro (youtube)</a>
      Poderá acessar estes songbooks elaborados no cifracil em pdf nos botões abaixo.
    </b-card-text>

    <b-button href="https://www.obrascompletas.eduardogudin.com/letras-com-cifras-volume-i" variant="primary" target="_blank" class="m-2">Songbook Volume 1 </b-button>
    <b-button href="https://www.obrascompletas.eduardogudin.com/letras-com-cifras-volume-ii" variant="primary" target="_blank" class="m-2">Songbook Volume 2 </b-button>

  </b-card>
 
</b-card-group>
</template>

<script>
export default {

}
</script>

<style>
.justificado {
  text-align: justify;
  text-justify: inter-word;
}
</style>