<template>
<b-row>
    <p class="display-4  text-center mt-3 text-primary" >FAQ - Perguntas e Respostas Frequentes</p>

<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1" v-for="(faq, index) in faqs" :key="faq.id">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block variant="info" v-b-toggle="'accordion-' + index">{{ faq.titulo }}</b-button>
      </b-card-header>
      <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div v-html="faq.texto"></div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

</b-row>
</template>

<script>
export default {
 data() {
        return {
            faqs: [],
            loading:false,
            erro:'',
            show:false
        }
    },
     methods: {
        getFaqs() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_faqs.php";
     
     
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
            console.log('getfaq')
            console.log(data)
          this.faqs = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
          alert(error)
        });
      }
    },
     mounted() {
        console.log('mounted faqs')
        this.getFaqs();

    }

}
</script>

<style>

</style>