<template>
   
  
        <b-row  align-h="center" class="m-3">   
          
            <b-col sm="12" md="6" lg="4" v-for="prod in produtos" v-bind:key="prod.id" >         
             <cfr-single-prod 
                v-bind:key="prod.id"
                v-bind:foto="prod.image"
                v-bind:prod="prod"
                > 
        </cfr-single-prod>
            </b-col>

       </b-row>
 
  
</template>

<script>
import CfrSingleProd from "../components/CfrSingleProd.vue"

import { mapGetters } from "vuex";
export default {
    props:['id'],  // id vem da url recebendo parametro grupo produto
    components: {
        CfrSingleProd

    },
    data() {
        return {
            produtos: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    computed: {

        ...mapGetters({
          itensCarrinho:'getCartItems',
          
          }),
          isMobile: function () {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          }

    },
    methods: {
       getProdutos() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_produtos.php"
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.produtos = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {
        console.log('created')
        this.getProdutos();
      

    },
    
   
}
</script>

<style scoped>

</style>